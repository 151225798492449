/* You can add global styles to this file, and also import other style files */
$core-purple: #A100FF;
$darker-purple: #7500C0;
$darkest-purple: #460073;

$secondary-blue: #0041F0;
$secondary-orange: #FF7800;
$secondary-green: #64FF50;
$secondary-yellow: #FFEB32;

$accent-purple: #B455AA;

$success: #4A8244;
$error: #BF5900;
$warning: #E32739;

$white: #FFFFFF;
$alabastar: #FAFAFA;
$white-smoke: #F2F2F2;
$gainsboro: #E5E5E5;
$ghost: #CCCBCE;
$french-gray: #B3B2B5;
$topaz: #837F89;
$gravel: #4F4B51;
$bastille: #1D1823;
$black: #000000;

@mixin transform($property) {
  /* primarily for scaling: ($property% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 1200px) { 
  .modal-dialog.modal-xl {
    width: 90%;
    min-width: 85%;
  }
}
@media (min-width: 600px) {
  .modal-dialog.modal-xl {
    width: 95%;
    min-width: 90%;
  }
}

.header span span .nav-item.end-item.dropdown { // override BVD header CSS rule
  min-width: 17.5rem;

  .option:last-child {
    border-top: $ghost 1px dashed;
  }
}

.big-text {
  font-size: 5rem;
  line-height: 5.5rem;
  font-weight: 300;
  padding: 2.5rem 2.5rem 2.5rem 0;

}

.failure {
  color: $gravel;
  letter-spacing: 1px;

  .big-text {
    font-weight: 500;
  }
  .small-text {
     padding-left: -1rem;
   }
}

.small-text {
  font-size: 1.5rem;
  font-weight: 250;
}

.bold {
  font-weight: 900;
  color: $darker-purple;
}

.link {
  cursor: pointer;
  &:hover, &:active {
    text-decoration: underline;
  }
}

.box-shadow {
  box-shadow: 5px 5px 5px $ghost;
}

.pointer {
  cursor: pointer;
}

.container {
  margin-top: 15vh;
}

.non-compliant {
  font-weight: 900;
  color: $warning;
}

.compliant {
  font-weight: 900;
  color: $success;
}

h1 {
  color: $darker-purple;
  text-decoration: none;
  border-bottom: 2.5px solid $darker-purple;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

#main-container a {
  color: $core-purple;
  text-decoration: underline;
  font-weight: 900;
}

.alert.alert-relative, .alert.alert-danger.alert-relative {
  position: relative;
}

/* Button Styles */
.btn.disabled {
  pointer-events: none;
}

.btn-primary {
  background-color: $darkest-purple;
  color: #ffffff;
  border-color: $darker-purple;

  &:active {
    background-color: $darkest-purple !important;
    border-color: $darker-purple !important;
  }
}

.btn-secondary { /* Override Bootstrap */
  color: #ffffff;
  border-color: $gravel;
}

.btn-secondary:disabled {
  opacity: 0.4;
  background-color: $gravel;
}

button:hover, button:focus {
  opacity: .8;
  box-shadow: none;
}

.btn-primary:hover {
  background-color: $darker-purple;
}

/* override BVD styles */
::ng-deep ngb-modal-backdrop {
  z-index: 1050 !important;
}

.modal-dialog.modal-lg {
  .form-group.inline.checkbox-text .form-text {
    width: 90% !important;
  }

  .modal-header {
    h5.modal-title span {
      color: $darker-purple;
      text-align: left;
      display: block;
    }

    .operations-border {
      border: 1px solid $darker-purple;
    }

    border-bottom: 1px solid $topaz !important;
  }

  .btn.btn-primary, .alert.alert-secondary {
    background-color: $darkest-purple;
    border-color: $darker-purple;
  }

  .btn.btn-primary:hover {
      background-color: $darker-purple;
  }

  .btn.btn-secondary {
    background-color: $gravel;

    &:hover {
      background-color: $topaz;
    }
  }
}

.modal-body {
  padding-left: 2.5rem;

  .alert.alert-secondary {
    margin-bottom: 2.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    a {
      color: $white;
      font-weight: 900;
    }

    input[type=checkbox]#unlock-confirmation-modal-checkbox:checked {
      background-color: $darker-purple;
    }
  }
}

/* Spinner Styles */
.spinner, .large-spinner, .tiny-spinner, .medium-spinner {
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 99;
}

svg.spinner {
  width: 40px;
  height: 40px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 40 40;
}

svg.spinner circle {
    fill: transparent;
    stroke: #BA55D3;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: 125.6;
    transform-origin: 20px 20px 0;
    animation: spinner 4s linear infinite;
}

svg.large-spinner {
  width: 240px;
  height: 240px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 240 240;
}
svg.large-spinner  circle {
    fill: transparent;
    stroke: #BA55D3;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: 753.6;
    transform-origin: 120px 120px 0;
    animation: largeSpinner 4s linear infinite;
}

svg.medium-spinner {
  width: 140px;
  height: 140px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 140 140;
}
svg.medium-spinner  circle {
    fill: transparent;
    stroke: #BA55D3;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: 439.6;
    transform-origin: 70px 70px 0;
    animation: mediumSpinner 4s linear infinite;
}

svg.tiny-spinner {
  width: 20px;
  height: 20px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 20 20;
}

svg.tiny-spinner circle {
    fill: transparent;
    stroke: #BA55D3;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: 62.8;
    transform-origin: 10px 10px 0;
    animation: tinySpinner 4s linear infinite;
}

svg.spinner.white-spinner circle {
  stroke: #ffffff;
}

@keyframes spinner {
  0% {
      stroke-dashoffset: 26.4;
      transform: rotate(0deg);
  } 50% {
      stroke-dashoffset: 125.6;
      transform: rotate(540deg);
  } 100% {
      stroke-dashoffset: 26.4;
      transform: rotate(1080deg);
  }
}

@keyframes largeSpinner {
  0% {
      stroke-dashoffset: 158.4;
      transform: rotate(0deg);
  } 50% {
      stroke-dashoffset: 753.6;
      transform: rotate(540deg);
  } 100% {
      stroke-dashoffset: 158.4;
      transform: rotate(1080deg);
  }
}

@keyframes mediumSpinner {
  0% {
      stroke-dashoffset: 92.4;
      transform: rotate(0deg);
  } 50% {
      stroke-dashoffset: 439.6;
      transform: rotate(540deg);
  } 100% {
      stroke-dashoffset: 92.4;
      transform: rotate(1080deg);
  }
}

@keyframes tinySpinner {
  0% {
      stroke-dashoffset: 13.2;
      transform: rotate(0deg);
  } 50% {
      stroke-dashoffset: 62.8;
      transform: rotate(540deg);
  } 100% {
      stroke-dashoffset: 13.2;
      transform: rotate(1080deg);
  }
}

/* Legend */
.legend-overlay {
  position: absolute;
  min-width: 21rem;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 2100;
}

.legend-icon:hover {
  @include transform(scale(1.05));
}

@media only screen and (max-width: 600px) {
  #legend {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .empty-div {
    margin: 0vw !important;
    text-align: left !important;
    font-size: 1rem !important;
  }
}

#legend-button {
  margin-left: 1.5rem;
}

#legend {
  border: solid 2px #E5E5E5;
  min-height: 3.16667rem;
  padding: 0px 1rem 0px 1rem;
  display: flex;
  align-items: center;
  color: $gravel;

  .legend-item {
    margin: .5rem;

    * {
      margin-right: .33rem;
    }

    svg {
      margin-bottom: -.25rem;
    }
  }
}

#detailed-device-table {
  width: 100%;

  th {
      border-bottom: 1px solid $gravel;
  }
  tr {
      border-bottom: 1px solid $gainsboro;
      &:last-child {
        border-bottom: none;
      }
  }
}